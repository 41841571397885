<template>
  <v-container fluid>
    <v-card>
      <v-card-title> Generated Exports </v-card-title>
      <div>
        <v-card-text v-for="(file, index) in payoutFiles" :key="index">
          {{ file }}
        </v-card-text>
      </div>
      <div>
        <v-card-text v-for="(file, index) in custodianFiles" :key="index">
          {{ file }}
        </v-card-text>
      </div>
      <v-row justify="space-between" class="ml-0 mr-0">
        <v-btn class="mb-2 ml-2" :color="colorTheme.button" dark @click="back">
          Back
        </v-btn>
        <v-btn
          class="mb-2 mr-2"
          :color="colorTheme.button"
          dark
          @click="goToExportPage()"
        >
          Go to Exports
        </v-btn>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  data: () => ({}),
  computed: {
    ...mapState("userConfig", ["colorTheme"]),
    ...mapState("exports", ["payoutExportResults", "custodianExportResults"]),
    payoutExists() {
      return (
        this.payoutExportResults != null && this.payoutExportResults.length > 0
      );
    },
    custodianExists() {
      return (
        this.custodianExportResults != null &&
        this.custodianExportResults.length > 0
      );
    },
    payoutFiles() {
      return this.payoutExportResults;
    },
    custodianFiles() {
      return this.custodianExportResults;
    }
  },
  methods: {
    back() {
      // to do all back butons
      if (
        this.$router.history._startLocation != this.$router.history.current.path
      ) {
        this.$router.go(-1);
      } else {
        this.$router.replace({ name: "FeeResultsAccounts" });
      }
    },
    goToExportPage() {
      //this.$router.push({ name: "DownloadPortal" });
      let newRoute = this.$router.resolve({ name: "DownloadPortal" });
      window.open(newRoute.href);
    }
  },
  mounted() {
    if (!this.payoutExists && !this.custodianExists) {
      this.$router.replace({ name: "FeeResultsAccounts" });
    }
  }
};
</script>
